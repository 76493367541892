<template>
  <div>
    <v-container class="mt-3" v-if="this.claimFields && !this.zeroSales && this.loading == false">
      <v-row justify="end">
        <v-btn class="mr-2" fab small @click="onSubmit" v-if="isEditing" :disabled="!valid">
          <v-icon>mdi-content-save</v-icon>
        </v-btn>
        <v-btn class="mr-2" fab small @click="onEdit" v-if="!claim.approved">
          <v-icon v-if="isEditing">mdi-close</v-icon>
          <v-icon v-else>mdi-pencil</v-icon>
        </v-btn>
      </v-row>
      <v-row v-for="(claimFieldRow, i) in this.claimFieldsByRow" :key="i">
        <template v-for="(claimField, j) in claimFieldRow">
          <v-col cols="12" v-if="claimField.newCategory" :key="j + '-category'">
            <v-subheader
              ><h3>{{ claimField.category }}</h3></v-subheader
            >
            <v-divider></v-divider>
          </v-col>
          <v-col :cols="claimField.cols" :key="j">
            <ClaimField
              v-if="claimField"
              :publicField="true"
              dense
              :label="claimField.name"
              :hint="claimField.description"
              :promotion="promotion"
              :claimField="claimField"
              v-model="claimFieldValues[claimField.id]"
              :readonly="!isEditing || claim.closed"
              :disabled="disabled"
            >
            </ClaimField>
          </v-col>
        </template>
      </v-row>
    </v-container>
    <v-container v-if="this.zeroSales">
      <v-row>
        <v-col cols="6">
          <b>Month: </b>
        </v-col>
        <v-col cols="6"> {{ zeroSalesDate }}</v-col>
      </v-row>
      <v-row>
        <v-col cols="6">
          <b>Sales: </b>
        </v-col>
        <v-col cols="6"> $0</v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
import ClaimField from "../../../gapp-components/components/fields/ClaimField.vue";
export default {
  components: { ClaimField },
  name: "ClaimFormTab",
  data: () => ({
    loading: false,
    claimFields: [],
    claimFieldsByRow: [],
    zeroSales: false,
    zeroSalesDate: null,
    promotion: null,
    claimFieldValues: [],
    isEditing: false
  }),
  props: {
    claim: Object,
    claimTypeId: Number,
    valid: Boolean,
    disabled: Boolean
  },
  methods: {
    async fetchData() {
      this.loading = true;

      let claimFields = await this.$api.get("/api/claimFields/byClaimTypeId/" + this.claimTypeId + "?sort=rank,ASC");
      this.claimFields = claimFields.data.content;
      this.claimFieldsByRow = [];
      let rowCount = -1;
      let colCount = 0;
      this.claimFields.forEach((claimField, i) => {
        if (!claimField.cols || claimField.cols < 1 || claimField.cols > 12) {
          claimField.cols = 12;
        }
        if (i == 0 || this.claimFields[i].category != this.claimFields[i - 1].category) {
          claimField.newCategory = true;
        } else {
          claimField.newCategory = false;
        }
        if (claimField.newCategory || colCount + claimField.cols > 12) {
          rowCount++;
          colCount = claimField.cols;
        } else {
          colCount += claimField.cols;
        }
        if (!this.claimFieldsByRow[rowCount]) {
          this.claimFieldsByRow[rowCount] = [];
        }
        this.claimFieldsByRow[rowCount].push(claimField);

        if (claimField.defaultValue && claimField.defaultValue.length > 0) {
          this.claimFieldValues[claimField.id] = claimField.defaultValue;
        }
      });

      let promotion = await this.$api.getRelatedObject("promotion", this.claim);
      this.promotion = promotion.data;

      let claimFieldValues = await this.$api.get("/api/claimFieldValues/byClaimId/" + this.claim.id);
      claimFieldValues.data.content.forEach(claimFieldValue => {
        this.claimFieldValues[claimFieldValue.claimFieldId] = claimFieldValue.value;
      });

      this.loading = false;
    },
    onSubmit() {
      this.$emit("onSubmit", this.claimFieldValues);
      this.isEditing = false;
      this.$forceUpdate();
    },
    onEdit() {
      this.isEditing = !this.isEditing;
      if (!this.isEditing) {
        this.fetchData();
      }
    }
  },
  mounted() {
    this.fetchData();
  },
  watch: {
    claimTypeId: {
      immediate: true,
      deep: true,
      handler(v) {
        this.claimTypeId = v;
      }
    },
    claim: {
      immediate: true,
      deep: true,
      handler() {
        this.fetchData();
      }
    }
  }
};
</script>
